<template>
  <div class="product-box">
    <div class="head-box animated fadeIn">
      <div class="content">
        <div class="title animated fadeInDown">等级医院评审系统</div>
      </div>
    </div>

    <div class="title1 animated zoomIn">1、解决评审第二部分“医疗服务能力与质量安全监测数据”相关指标数据自动采集计算展现需求</div>
    <div class="box1" style="margin-bottom: 80px;">
      <img class="img animated zoomIn" style="width: 400px;" src="@/assets/img/product/11.png" />
    </div>
    <div class="box2-box" v-for="(val, index) in initData" :key="val.id" :id="val.id">
      <div class="box2" v-if="isEven(index)">
        <div class="text-box animated" :class="{ bounceInLeft: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <div class="title">{{val.title}}</div>
          <div class="desc">{{val.desc}}</div>
        </div>
        <div class="img-box animated" :class="{ bounceInRight: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <img class="img" :style="{width: val.width}" :src="val.img" />
        </div>
      </div>
      <div class="box2" v-else>
        <div class="img-box animated" :class="{ bounceInLeft: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <img class="img" :style="{width: val.width}" :src="val.img" />
        </div>
        <div class="text-box animated" :class="{ bounceInRight: val.show }" :style="{ visibility: val.show ? 'visible':'hidden' }">
          <div class="title">{{val.title}}</div>
          <div class="desc">{{val.desc}}</div>
        </div>
      </div>
    </div>
    <div class="box1" :id="animatedList[0].id">
      <div class="title1 animated" :class="{ zoomIn: animatedList[0].show }" :style="{ visibility: animatedList[0].show ? 'visible':'hidden' }">2、解决第三部分现场检查的所有条款信息化管理，辅助评审更易达标</div>
    </div>
    <div class="box1" :id="animatedList[1].id" style="margin-bottom: 60px;">
      <img class="img animated" :class="{ zoomIn: animatedList[1].show }" :style="{ visibility: animatedList[1].show ? 'visible':'hidden', width: '500px' }" src="@/assets/img/product/18.png" />
    </div>
    <div class="box1" :id="animatedList[2].id">
      <div class="name animated" :class="{ flipInX: animatedList[2].show }" :style="{ visibility: animatedList[2].show ? 'visible':'hidden', color: '#4aa1ec' }">等级评审管理流程-如何实现PDCA</div>
    </div>
    <div class="box1" :id="animatedList[3].id" style="margin-bottom: 60px;">
      <img class="img animated" :class="{ zoomIn: animatedList[3].show }" :style="{ visibility: animatedList[3].show ? 'visible':'hidden', width: '500px' }" src="@/assets/img/product/19.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initData: [
        {
          id: 'animatedBox1',
          show: false,
          title: '数据自动采集，规范统计口径',
          desc: '系统通过构建全院指标池建立标准化的等级医院评审第二部分完整指标体系，实现数据的系统采集和填报导入，提升数据管理效能、降低数据运维成本。',
          img: require('@/assets/img/product/12.png'),
          width: '100%',
        },
        {
          id: 'animatedBox2',
          show: false,
          title: '支持人工数据补录，完善指标体系数据',
          desc: '支持向科室下发指标数据补录任务，弥补部分医院系统无法提供数据的不足，完善指标体系数据，满足医院指标质量管理工作需要。',
          img: require('@/assets/img/product/13.png'),
          width: '90%',
        },
        {
          id: 'animatedBox3',
          show: false,
          title: '数据溯源，详细钻取追踪到原始数据',
          desc: '系统深度挖掘数据，支持溯源到原始数据，方便科室进行数据循证及评审专家数据复核。',
          img: require('@/assets/img/product/14.png'),
          width: '100%',
        },
        {
          id: 'animatedBox4',
          show: false,
          title: '自动预警提醒，有利精准改进',
          desc: '可按照指标的目标值、指标导向、同环比值等维度，为定量指标、定性指标开展持续监测和自动预警提醒，实现安全质量薄弱环节定位，为缺陷改进工作提供数据支持。',
          img: require('@/assets/img/product/15.png'),
          width: '80%',
        },
        {
          id: 'animatedBox5',
          show: false,
          title: 'BI实时展示，管理决策有依据',
          desc: '支持对于评审工作进度及指标数据实时监测，满足医院评审指标管理需求，以数据为导向，数据驱动医院医疗质量精细化管理、数字化评审。',
          img: require('@/assets/img/product/16.png'),
          width: '60%',
        },
        {
          id: 'animatedBox6',
          show: false,
          title: '质控手册及评审报告自定义生成',
          desc: '支持职能科室将每日、每月、每季度、每年繁琐的质控报告编制工作从手工状态解放出来，系统自动将报告内容以预先设定好的医院报告模板组合，最终实现一键导出。',
          img: require('@/assets/img/product/17.png'),
          width: '60%',
        },
      ],
      animatedList: [
        { show: false, id: 'animatedBox7' },
        { show: false, id: 'animatedBox8' },
        { show: false, id: 'animatedBox9' },
        { show: false, id: 'animatedBox10' },
      ],
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.initData.forEach(v => {
        let element = document.getElementById(v.id)
        // 获取元素的位置和尺寸
        let rect = element.getBoundingClientRect();
        // 计算元素和浏览器窗口底部之间的距离
        let distance = window.innerHeight - rect.bottom;
        if (distance > -300) {
          v.show = true
        }
      })

      this.animatedList.forEach(v => {
        let element = document.getElementById(v.id)
        // 获取元素的位置和尺寸
        let rect = element.getBoundingClientRect();
        // 计算元素和浏览器窗口底部之间的距离
        let distance = window.innerHeight - rect.bottom;
        if (distance > -300) {
          v.show = true
        }
      })
    },
    isEven(number) {
      return number % 2 === 0;
    },
  }
}
</script>

<style lang="less" scoped>
.product-box {
  height: 100%;
  padding-top: 68px;
  color: #000;
  font-weight: 500;
  .head-box {
    position: relative;
    width: 100%;
    height: 500px;
    background: url("~@/assets/img/product/1.png") no-repeat center/cover;
    margin-bottom: 100px;
    .content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: 100px 12% 40px;
      font-weight: bold;
      font-family: "楷体", "楷体_GB2312";
    }
    .title {
      font-size: 100px;
      font-weight: 500;
      text-shadow: 0 0 1px currentColor, -1px -1px 1px #000, 0 -1px 1px #000,
        1px -1px 1px #000, 1px 0 1px #000, 1px 1px 1px #000, 0 1px 1px #000,
        -1px 1px 1px #000, -1px 0 1px #000;
    }
    .tip {
      margin-top: 32px;
      font-size: 40px;
    }
  }
  .title1 {
    padding: 0 100px;
    text-align: center;
    font-size: 36px;
    color: #000;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .name {
      margin-bottom: 20px;
      font-size: 40px;
      color: #333;
      font-family: "宋体";
      font-weight: 900;
    }
  }
  .box2-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box2 {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 100px;
    margin-bottom: 80px;
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #4aa1ec;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        color: #333;
      }
    }
  }
}
</style>
